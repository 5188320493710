<template>
  <el-card header="经销商列表" shadow="never">
    <div class="head-container">
      <!-- <quick-select v-model="query.enterpriseType" filterable clearable url="api/dictDetail" display-field="label" :params="{dictName: 'enterprise_type'}" placeholder="企业类型" style="width: 150px; margin-right: 6px;" />
      <quick-select ref="levels" v-model="query.level" filterable url="api/distributorLeve/list" placeholder="经销商类型" @change="toQuery" clearable class="filter-item" style="width: 150px" />-->
      <el-input v-model="query.erpId" clearable placeholder="编码搜索" style="width: 120px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.name" clearable placeholder="经销商名称搜索" style="width: 140px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" highlight-current-row @current-change="handleDistributorChange" style="width: 100%;">
      <el-table-column prop="erpId" label="经销商编码" width="120" />
      <el-table-column prop="name" label="经销商名称" min-width="300" />
      <!-- <el-table-column label="经销商类型" width="100" :formatter="r => {return getDistributorLevelName(r.level);}" /> -->
      <!-- <el-table-column label="所在地区" width="200">
        <template slot-scope="scope">{{getAreaStr(scope.row)}}</template>
      </el-table-column>-->
      <!-- <el-table-column label="登记时间" :formatter="r => { return new Date(r.registerTime).format('yyyy/MM/dd'); }" width="100" /> -->
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </el-card>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    value: String | Number,
  },
  data() {
    return {
      query: {
        enterpriseType: null,
        level: null,
        name: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributor";
      const sort = "id,desc";
      this.query.enabled = true;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      this.handleDistributorChange(null);
      return true;
    },
    getAreaStr(row) {
      let s = [];
      if (row.provinceName) s.push(row.provinceName);
      if (row.cityName) s.push(row.cityName);
      if (row.districtName) s.push(row.districtName);
      return s.join(" / ");
    },
    getDistributorLevelName(val) {
      return this.$refs.levels ? this.$refs.levels.getDisplayText(val) : "";
    },
    handleDistributorChange(d) {
      this.$emit("input", d);
    },
  },
};
</script>