<template>
  <div>
    <div class="head-container filter-item">
      <el-button :disabled="!savable" :loading="saving" type="primary" @click="handleSubmit">保存</el-button>

      <div style="margin-left:20px">
        <el-checkbox v-model="isValid" style="margin-top:7px">授权时限</el-checkbox>&nbsp;
        <el-date-picker v-if="isValid" v-model="validDate" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </div>
    </div>
    <el-tree ref="tree" :data="treeData" show-checkbox node-key="_id" default-expand-all v-loading="loading">
      <span slot-scope="{data}">
        {{data.name}}
      </span>
    </el-tree>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    isGroup: Boolean,
    distributor: String | Number,
    group: String | Number,
  },
  data() {
    return {
      treeData: null,
      loading: false,
      saving: false,
      isValid: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      validDate: [],
    };
  },
  watch: {
    isGroup: "load",
    distributor: "load",
    group: "load",
  },
  computed: {
    savable() {
      if (this.isGroup) return !!this.group;
      else return !!this.distributor;
    },
  },
  methods: {
    generateOwnId(source, brandId) {
      if (source && source.length) {
        source.forEach((o) => {
          o._type = "series";
          o._id = brandId + "_" + o.id;
          this.generateOwnId(o.children, brandId);
        });
      }
    },
    load() {
      if (this.savable) {
        this.isValid = false;
        this.validDate = [];
        this.loading = true;
        request({
          url: this.isGroup
            ? `api/distributorGroupGoods?groupId=${this.group.id}`
            : `api/distributor/${this.distributor.id}/goodsSet`,
          method: "get",
        })
          .then((res) => {
            if (this.$refs.tree) {
              let keys = res.map((o) => {
                if (o.begAt && o.endAt && !this.isValid) {
                  this.isValid = true;
                  this.validDate.push(o.begAt);
                  this.validDate.push(o.endAt);
                }
                return o.seriesId
                  ? o.brandId + "_" + o.seriesId
                  : o.brandId + "";
              });
              let leafKeys = keys.filter((k) => {
                let node = this.$refs.tree.getNode(k);
                return node && node.isLeaf;
              });
              this.$refs.tree.setCheckedKeys(leafKeys);
            }
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.$refs.tree && this.$refs.tree.setCheckedKeys([]);
      }
    },
    loadBrandAndSeries() {
      request({
        url: "api/brand",
        method: "get",
        params: {
          page: 0,
          size: 1000,
        },
      }).then((res) => {
        let dek = [];
        res.content.forEach((o) => {
          o._type = "brand";
          o._id = o.id + "";
          o.children = [];
          dek.push(o._id);
        });
        this.defaultExpandKeys = dek;
        request({
          url: "api/series/tree",
          method: "get",
        }).then((res1) => {
          res1.content.forEach((o) => {
            o._type = "series";
            let b = res.content.find((_b) => {
              return _b.id === o.brandId;
            });
            if (b) {
              b.disabled = false;
              b.children.push(o);
              o._id = b.id + "_" + o.id;
              this.generateOwnId(o.children, b.id);
            }
          });
          let os = [];
          res.content.forEach((o) => {
            if (o && o.children && o.children.length) {
              os.push(o);
            }
          });

          this.treeData = os;
        });
      });
    },
    handleSubmit() {
      if (this.savable) {
        let cns =
          this.$refs.tree && this.$refs.tree.getCheckedNodes(false, true);
        let ds = [];
        cns.forEach((n) => {
          if (n && n._type === "series") {
            let o = {
              brandId: n.brandId,
              seriesId: n.id,
            };
            if (!this.isGroup) o.buyerId = this.distributor.enterpriseId;
            if (this.isValid && this.validDate && this.validDate.length) {
              o.begAt = this.validDate[0];
              o.endAt = this.validDate[1];
            } else {
              o.begAt = null;
              o.endAt = null;
            }
            ds.push(o);
          }
        });
        this.saving = true;
        request({
          url: this.isGroup
            ? `api/distributorGroupGoods/${this.group.id}`
            : `api/distributorGoods/${this.distributor.enterpriseId}`,
          method: "post",
          data: ds,
        })
          .then((res) => {
            this.$notify({
              title: "品牌/系列授权成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.saving = false;
          });
      }
    },
  },
  mounted() {
    this.loadBrandAndSeries();
  },
};
</script>