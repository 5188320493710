<template>
  <div class="app-container">
    <el-tabs v-model="tab">
      <el-tab-pane name="group" label="按经销商组进行授权" />
      <el-tab-pane name="single" label="指定经销商进行授权" />
    </el-tabs>
    <div class="h s">
      <group-list v-model="group" v-show="tab === 'group'" style="margin-right: 20px;" />
      <single-list v-model="distributor" v-show="tab === 'single'" style="margin-right: 20px;" />
      <el-card class="box-card flex" shadow="never">
        <div slot="header" class="clearfix">
          <span>授权设置</span>
          <div style="float: right">
            <el-radio-group v-model="setTab" style="margin: -10px 0;">
              <el-radio-button label="series">授权品牌/系列</el-radio-button>
              <el-radio-button label="spu">授权SPU商品</el-radio-button>
              <el-radio-button label="sku">授权SKU商品</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <series :distributor="distributor" :group="group" :is-group="tab === 'group'" v-show="setTab === 'series'" />
        <spu :distributor="distributor" :group="group" :is-group="tab === 'group'" v-show="setTab === 'spu'" />
        <sku :distributor="distributor" :group="group" :is-group="tab === 'group'" v-show="setTab === 'sku'" />
      </el-card>
    </div>
  </div>
</template>

<script>
import GroupList from "./group";
import SingleList from "./single";
import Series from "./series";
import Spu from "./spu";
import Sku from "./sku";

export default {
  components: { GroupList, SingleList, Series, Spu, Sku },
  data() {
    return {
      tab: "group",
      setTab: "series",
      distributor: null,
      group: null
    };
  }
};
</script>